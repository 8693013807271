// ローディング判定
jQuery(window).on("load", function() {
  jQuery("body").attr("data-loading", "true");
});

jQuery(function() {
  // スクロール判定
  jQuery(window).on("scroll", function() {
    let scrollTop = jQuery(this).scrollTop();
    let windowHeight = jQuery(this).height();
    let documentHeight = jQuery(document).height();

    if (400 < scrollTop) {
      jQuery("body").attr("data-scroll", "true");
    } else {
      jQuery("body").attr("data-scroll", "false");
    }

    if (documentHeight - (windowHeight + scrollTop) <= 0) {
      jQuery("body").attr("data-scroll-bottom", "true");
    } else {
      jQuery("body").attr("data-scroll-bottom", "false");
    }
  });

  /* ドロワー */
  jQuery(".js-drawer").on("click", function(e) {
    e.preventDefault();
    let targetClass = jQuery(this).attr("data-target");
    let ariaControls = jQuery(this).attr("aria-controls");
    jQuery("." + targetClass).toggleClass("is-checked");
    jQuery(body).toggleClass("is-checked");

    if (jQuery("#" + ariaControls).attr("aria-hidden") === "true") {
      jQuery("#" + ariaControls).attr("aria-hidden", "false");
    } else {
      jQuery("#" + ariaControls).attr("aria-hidden", "ture");
    }

    if (jQuery(this).attr("aria-expanded") === "true") {
      jQuery(this).attr("aria-expanded", "false");
    } else {
      jQuery(this).attr("aria-expanded", "ture");
    }
    return false;
  });

  jQuery(".sp-btn").on("click", function() {
    jQuery(this)
      .next()
      .slideToggle();
  });
});

var urlHash = location.hash;
if (urlHash) {
  jQuery("body,html")
    .stop()
    .scrollTop(0);

  setTimeout(function() {
    var target = jQuery(urlHash);
    var position = target.offset().top - 80;
    //ランダムっぽいのでどちらにも対応
    jQuery("body,html")
      .stop()
      .animate({ scrollTop: position }, 300);
  }, 100);
}

jQuery('a[href^="#"]').click(function() {
  let id = jQuery(this).attr("href");
  let speed = 300;
  let target = jQuery("#" == id ? "html" : id);
  let position = jQuery(target).offset().top - 80;
  if ("fixed" !== jQuery("header").css("position")) {
    position = jQuery(target).offset().top;
  }

  console.log("ok");

  jQuery("html, body").animate(
    {
      scrollTop: position
    },
    speed
  );
  return false;
});

jQuery(function() {
  // aimation呼び出し
  if (jQuery(".js-scroll-trigger").length) {
    scrollAnimation();
  }

  // aimation関数
  function scrollAnimation() {
    jQuery(window).scroll(function() {
      jQuery(".js-scroll-trigger").each(function() {
        let position = jQuery(this).offset().top,
          scroll = jQuery(window).scrollTop(),
          windowHeight = jQuery(window).height();

        if (scroll > position - windowHeight + 200) {
          jQuery(this).addClass("active");
        }
      });
    });
  }
  jQuery(window).trigger("scroll");
});

//wait
jQuery(function() {
  "use waits";

  var dialog = document.getElementById("dialog");
  var waits = document.getElementsByClassName("wait");
  var cancel = document.getElementById("cancel");
  var scrollpos;
  //??????????????????????

  for (let i = 0; i < waits.length; i++) {
    waits[i].addEventListener(
      "click",
      function() {
        dialog.style.visibility = "visible";
        dialog.style.opacity = 1;
        scrollpos = jQuery(window).scrollTop();
        jQuery("body")
          .addClass("fixed")
          .css({ top: -scrollpos });
      },
      false
    );
  }

  cancel.addEventListener("click", function() {
    jQuery("body")
      .removeClass("fixed")
      .css({ top: 0 });
    window.scrollTo(0, scrollpos);
    dialog.style.visibility = "hidden";
    dialog.style.opacity = 0;
  });
});

//headerスクロール fix
/* jQuery(window).on("scroll", function() {
  jQuery("header").css("left", -jQuery(window).scrollLeft());
}); */

if (window.matchMedia("(min-width: 769px)").matches) {
  /* ウィンドウサイズが 768px以上の場合に有効にしたい処理*/
}
var _window = jQuery(window),
  _header = jQuery("header"),
  heroBottom;

_window.on("scroll", function() {
  /*         heroBottom1 = jQuery(".mv").height();
  heroBottom2 = jQuery(".header-logo").height();
  heroBottom = heroBottom1 - heroBottom2; */
  if (_window.scrollTop() > 20) {
    _header.addClass("transform");
  } else {
    _header.removeClass("transform");
  }
});
_window.trigger("scroll");

var urlHash = location.hash;
if (urlHash) {
  jQuery("body,html")
    .stop()
    .scrollTop(0);

  setTimeout(function() {
    var target = jQuery(urlHash);
    var position = target.offset().top - 70;
    //ランダムっぽいのでどちらにも対応
    jQuery("body,html")
      .stop()
      .animate({ scrollTop: position }, 300);
    console.log(header);
    console.log(position);
  }, 100);
}

/* function initMap() {
  var latlng = { lat: 35.658212, lng: 139.698294 };
  var map = new google.maps.Map(document.getElementById("map"), {
    zoom: 16,
    center: latlng
  });
  //マーカーの設定
  var marker = new google.maps.Marker({
    position: latlng,
    map: map
        icon: new google.maps.MarkerImage(
      "../wp-content/uploads/2020/05/pin2.png", //マーカー画像URL
      new google.maps.Size(64, 64), //マーカー画像のサイズ
      new google.maps.Point(0, 0), //マーカー画像表示の起点（変更しない）
      new google.maps.Point(20, 50) //マーカー位置の調整
    )
  });
} */
